// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/iosIndex","id":"1"},"2":{"name":"ios审核","path":"/iosIndex","id":"2"},"3":{"name":"android审核","path":"/androidIndex","id":"3"},"4":{"path":"/disposeAccount","exact":true,"id":"4"},"5":{"name":"ios审核","path":"/worktopIos","id":"5"},"6":{"name":"android审核","path":"/worktopAndroid","id":"6"}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import(/* webpackChunkName: "p__iosIndex__index" */'@/pages/iosIndex/index.tsx')),
'3': React.lazy(() => import(/* webpackChunkName: "p__androidIndex__index" */'@/pages/androidIndex/index.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "p__DisposeAccount__index" */'@/pages/DisposeAccount/index.jsx')),
'5': React.lazy(() => import(/* webpackChunkName: "p__worktopIos__index" */'@/pages/worktopIos/index.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__worktopAndroid__index" */'@/pages/worktopAndroid/index.tsx')),
},
  };
}
